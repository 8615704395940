<template>
    <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Log') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-text-field  :dark="$store.state.isDarkMode"  :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode"  :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" clearable>
          </v-text-field>
        </v-col>
        <v-col md="4">
              
          <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.user_id"
          @keypress="fetchUsers" :loading="usersLoading"
                                :label="$t('user name')" clearable></v-autocomplete>
        </v-col>
        <v-col md="2">
            <v-autocomplete
                color="#757575" 
                :label="$t('type')"
                :items="type"
                item-text="name"
                item-value="value"
                v-model="filters.type"
                
              ></v-autocomplete>
          </v-col>
          <v-col md="9">
            <v-text-field
                color="#757575" 
                :label="$t('text')"
          
                v-model="filters.text"
                
              ></v-text-field>
          </v-col>
       
          <v-col md="3">

          </v-col>

        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
      </v-row>
  


      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 px-10"
        hide-default-footer
        :items-per-page="10"
      >
      <!-- <template  v-slot:item.actions="{ item }">
        
        <v-btn @click="detailsHandler(item)" color="green" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn
        >
      </template> -->

      </v-data-table>
      <v-pagination
        v-if="!loading && tableItems.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div> 
  </template>
  
  <script>
 
  import axios from 'axios'
  import i18n from '@/i18n';
  import debounce from "../../../../../helpers/debounce";
  import spinner from "../../../../../components/base/spinner.vue";
  import pagination from "../../../../../components/base/pagination.vue";
  import { saveAs } from 'file-saver';

  
  export default {
    components: {
    appSpinner: spinner,
    appPagination: pagination,
  },
    data: () => ({
        usersLoading:null,
        users: [],
      is_all: false,
      paginated: true,
      grades: null,
      gradesLoading: false,
      eduClasses: null,
      eduClassesLoading: false,
      childrens: null,
      childrensLoading: false,
      tableItems: [],
      type: [
        {
          name: "store",
          value: "store",
        },
        {
          name: "update",
          value: "update",
        },
        {
          name: "index",
          value: "index",
        },
        {
          name: "show",
          value: "show",
        },
        {
          name: "delete",
          value: "delete",
        },
        {
          name: "not_defined",
          value: "not_defined",
        },
      ],
      loading: false,
      page: 1,
      total: 1,
      // tableHeaders: ["date", "route name", "driver name",  "Number Students", "Absent","Start Time", "End Time", "Details"],
      headers: [
        { text: i18n.t("id"), value: "id" },  
        { text: i18n.t("date"), value: "date" },
        { text: i18n.t('user name'), value: "user" },
        { text: i18n.t('type'), value: "type" },
        { text: i18n.t('Details'), value: "text" },
      
        
      ],
      
    
  
      filters: {
        type: null,
        user_id: null,
        text: null,
        from: null,
        to: null,
       
      },
    }),
    

    methods: {
      detailsHandler(item) {
     
      this.$router.push(
        `detailsTripsReport?trip_id=${item.id}`
      );
  
  },
      goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
      async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("/edu-class",{
          params: {
            paginated: false,
          }
        });
        this.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
    async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
    async exportToExcel(queryParams){
         
         try {
    
     const excel = await axios.get(`/log/export`, {
       params: {
         ...queryParams,
         
       },
       responseType: 'blob'
     });
     saveAs(excel.data)
     

   } catch (err) {
     console.log('err', err);
   } finally {
     
   }
     
     },
   
      clearFilter() {
        this.filters.user_id =  undefined;
        this.filters.type = undefined; 
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.text = undefined;
      
        this.page = 1;
        this.getAll();
      },
   
      async getAll() {
        try {
         
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          
          const res = await axios.get("/log/report", {
            params: {
              size: 10,
              page: this.page,
              paginated: this.paginated,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
        //     this.tableItems.forEach((item) => {
        //   item.uid = this.$uuid.v1();
        // });
          this.total = res.data.meta.total;
          this.page = res.data.meta.current_page;
        } finally {
          this.loading = false;
        }
      },
      applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
      async groupAc() {
            try {
          const searchResult = await axios.get(
            "/meal-group"
          );
          this.meal_group = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 
      },
 
    },
    async created() {
      this.getAll();
     
    },
  };
  </script>
  
  <style></style>
  